<template>
	<div class="page">
		<!-- <div class="viewTitle">短信设置(发送至会员)</div>
		<div class="comView">
			<div class="comName">收银默认发送短信:</div>
			<el-radio-group v-model="cashiermessage" @change="handleUpdateChange(0)">
				<el-radio :label="true">是</el-radio>
				<el-radio :label="false">否</el-radio>
			</el-radio-group>
		</div>
		<div class="comView">
			<div class="comName">充值默认发送短信:</div>
			<el-radio-group v-model="rechargemessage" @change="handleUpdateChange(1)">
				<el-radio :label="true">是</el-radio>
				<el-radio :label="false">否</el-radio>
			</el-radio-group>
		</div> -->
		<div class="viewTitle">提成设置</div>
		<div class="comView">
			<div class="comName">商品提成计算方式:</div>
			<el-radio-group v-model="goodsroyaltysetting" @change="handleUpdateChange(2)">
				<el-radio :label="true">按零售价计算</el-radio>
				<el-radio :label="false">按折后价计算</el-radio>
			</el-radio-group>
		</div>
		<div class="comView" style="margin-bottom: 32px;">
			<div class="comName">服务提成计算方式:</div>
			<el-radio-group v-model="serviceroyaltysetting" @change="handleUpdateChange(3)">
				<el-radio :label="true">按零售价计算</el-radio>
				<el-radio :label="false">按折后价计算</el-radio>
			</el-radio-group>
		</div>
	
		<div class="viewTitle">小票配置</div>
		<div class="comView" style="display: flex;align-items: center;">
			<div class="comName">结账单模版:</div>
			<el-select v-model="jiezhangdansetting" placeholder="请选择" style="width: 200px;" clearable
				@change="handleUpdateChange(4)">
				<el-option v-for="item in List" :key="item.value" :label="item.name" :value="item.value">
					<el-tooltip placement="right" effect="light">
						<div slot="content">
							<img :src="item.param1" style="width:200px;" />
						</div>
						<div>{{ item.name }}</div>
					</el-tooltip>
				</el-option>
			</el-select>
		</div>
		<div class="comView" style="display: flex;align-items: center;">
			<div class="comName">充值小票模版:</div>
			<el-select v-model="chongzhixiaopiaosetting" placeholder="请选择" style="width: 200px;" clearable
				@change="handleUpdateChange(5)">
				<el-option v-for="item in List1" :key="item.value" :label="item.name" :value="item.value">
					<el-tooltip placement="right" effect="light">
						<div slot="content">
							<img :src="item.param1" style="width:200px;" />
						</div>
						<div>{{ item.name }}</div>
					</el-tooltip>
				</el-option>
			</el-select>
		</div>
		<div class="comView" style="display: flex;align-items: center;">
			<div class="comName">产品小票模版:</div>
			<el-select v-model="chanpinjiaqiansetting" placeholder="请选择" style="width: 200px;" clearable
				@change="handleUpdateChange(6)">
				<el-option v-for="item in List2" :key="item.value" :label="item.name" :value="item.value">
					<el-tooltip placement="right" effect="light">
						<div slot="content">
							<img :src="item.param1" style="width:200px;" />
						</div>
						<div>{{ item.name }}</div>
					</el-tooltip>
				</el-option>
			</el-select>
		</div>
		<div class="viewTitle">系统设置</div>
		<div class="comView" style="margin-bottom: 32px;">
			<div class="comName">打印安装插件:(点击图标进行下载)</div>
			<div class="comImg" @click="handlePrinter"><img src="../../../assets/img/cangku/Printer.png" alt=""></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			cashiermessage: '',  //收银短信
			cashierObj: {},  //收银数据
			rechargemessage: '',  //充值短信
			rechargeObj: {},   //充值数据
			goodsroyaltysetting: '',  //商品提成
			goodsObj: {},  //商品数据
			serviceroyaltysetting: '',  //服务提成
			serviceObj: {},  //服务数据
			downloading: false, //跟踪文件是否正在下载
			jiezhangdanObj: {},
			jiezhangdansetting: '',
			chongzhixiaopiaosetting: '',
			chongzhixiaopiaoObj: {},
			chanpinjiaqiansetting: '',
			chanpinjiaqianObj: {},
			List: [],
			List1: [],
			List2: [],

		}
	},
	mounted() {
		
		this.getCashiermessageData()
		this.getRechargemessageData()
		this.getGoodsroyaltysettingData()
		this.getServiceroyaltysettingData()
		this.getjiezhangdansettingData()
		this.getchanpinjiaqiansettingData()
		this.getchongzhixiaopiaosettingData()
		this.getProductData()
		this.getProductData1()
		this.getProductData2()
		
	},
	methods: {
		//获取类目
		getProductData() {
			let params = {
				code: 'jiezhangdan',
				companyId: '668638e374396787c0371b60'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.List = res.data.itemList;
					res.data.itemList.forEach(item => {
						if (item.isDef == true) {
							console.log(111, item.value);
							return this.jiezhangdansetting = item.value
						}
					});
				}
			})
		},
		getProductData1() {
			let params = {
				code: 'chongzhixiaopiao',
				companyId: '668638e374396787c0371b60'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.List1 = res.data.itemList;
					res.data.itemList.forEach(item => {
						if (item.isDef == true) {
							return this.chongzhixiaopiaosetting = item.value
						}
					});
				}
			})
		},
		getProductData2() {
			let params = {
				code: 'chanpinjiaqian',
				companyId: '668638e374396787c0371b60'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.List2 = res.data.itemList;
					res.data.itemList.forEach(item => {
						if (item.isDef == true) {
							return this.chanpinjiaqiansetting = item.value
						}
					});
				}
			})
		},
		// 下载打印插件
		handlePrinter() {
			if (this.downloading) return;
			this.downloading = true;
			const link = document.createElement("a");
			link.href = 'https://huashengchong.oss-cn-beijing.aliyuncs.com/tools/hsc-print.exe';
			link.setAttribute("download", "hsc-print.exe");
			document.body.appendChild(link);
			link.click();
			setTimeout(() => {
				document.body.removeChild(link);
				this.downloading = false;
			}, 10000); // 设置节流时间，例如1秒

		},

		//修改状态
		handleUpdateChange(type) {
			let params = {};
			if (type == 0) {  //收银短信
				params.name = this.cashierObj.name;
				params.code = this.cashierObj.code;
				params.value = this.cashiermessage + '';
				params.remark = this.cashierObj.remark;
				params.param = this.cashierObj.param;
				params.companyInfo = this.cashierObj.companyInfo;
				params.id = this.cashierObj.id;
			} else if (type == 1) {   //充值短信
				params.name = this.rechargeObj.name;
				params.code = this.rechargeObj.code;
				params.value = this.rechargemessage + '';
				params.remark = this.rechargeObj.remark;
				params.param = this.rechargeObj.param;
				params.companyInfo = this.rechargeObj.companyInfo;
				params.id = this.rechargeObj.id;
			} else if (type == 2) {   //商品提成
				params.name = this.goodsObj.name;
				params.code = this.goodsObj.code;
				params.value = this.goodsroyaltysetting + '';
				params.remark = this.goodsObj.remark;
				params.param = this.goodsObj.param;
				params.companyInfo = this.goodsObj.companyInfo;
				params.id = this.goodsObj.id;
			} else if (type == 3) {  //服务提成
				params.name = this.serviceObj.name;
				params.code = this.serviceObj.code;
				params.value = this.serviceroyaltysetting + '';
				params.remark = this.serviceObj.remark;
				params.param = this.serviceObj.param;
				params.companyInfo = this.serviceObj.companyInfo;
				params.id = this.serviceObj.id;
			} else if (type == 4) {  //小票
				params.name = this.jiezhangdanObj.name;
				params.code = this.jiezhangdanObj.code;
				params.value = this.jiezhangdansetting + '';
				params.remark = this.jiezhangdanObj.remark;
				params.param = this.jiezhangdanObj.param;
				params.companyInfo = this.jiezhangdanObj.companyInfo;
				params.id = this.jiezhangdanObj.id;
			} else if (type == 5) {  //小票
				params.name = this.chongzhixiaopiaoObj.name;
				params.code = this.chongzhixiaopiaoObj.code;
				params.value = this.chongzhixiaopiaosetting + '';
				params.remark = this.chongzhixiaopiaoObj.remark;
				params.param = this.chongzhixiaopiaoObj.param;
				params.companyInfo = this.chongzhixiaopiaoObj.companyInfo;
				params.id = this.chongzhixiaopiaoObj.id;
			} else if (type == 6) {  //小票
				params.name = this.chanpinjiaqianObj.name;
				params.code = this.chanpinjiaqianObj.code;
				params.value = this.chanpinjiaqiansetting + '';
				params.remark = this.chanpinjiaqianObj.remark;
				params.param = this.chanpinjiaqianObj.param;
				params.companyInfo = this.chanpinjiaqianObj.companyInfo;
				params.id = this.chanpinjiaqianObj.id;
			}
			this.$http.post("/cms/System/UpdateSystemConfig", params).then(res => {
				if (res.code === 0) {
					console.log(res,'小票');
					if(type==4){
						this.$store.commit("mendian/setJiezhangdanState", res.data.value)
					}else if(type==5){
						this.$store.commit("mendian/setChongzhixiaopiaoState", res.data.value)
					}else if(type==6){
						this.$store.commit("mendian/setChanpinjiaqianState", res.data.value)
					}
					console.log('修改', res)
				}
			})
		},
		// 小票打印
		getjiezhangdansettingData() {
			let params = {
				code: 'jiezhangdan'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					console.log(res.data, 'res.data');
					if (res.data.value) {
						this.jiezhangdansetting = res.data.value;
					}
					this.jiezhangdanObj = res.data;
				}
			})
		},
		// 小票打印
		getchanpinjiaqiansettingData() {
			let params = {
				code: 'chanpinjiaqian'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value) {
						this.chanpinjiaqiansetting = res.data.value;
					}
					this.chanpinjiaqianObj = res.data;
				}
			})
		},
		// 小票打印
		getchongzhixiaopiaosettingData() {
			let params = {
				code: 'chongzhixiaopiao'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value) {
						this.chongzhixiaopiaosetting = res.data.value;
					}
					this.chongzhixiaopiaoObj = res.data;
				}
			})
		},
		//商品提成
		getGoodsroyaltysettingData() {
			let params = {
				code: 'goodsroyaltysetting'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value == 'false') {
						this.goodsroyaltysetting = false;
					} else if (res.data.value == 'true') {
						this.goodsroyaltysetting = true;
					}
					this.serviceObj = res.data;
				}
			})
		},
		//服务提成
		getServiceroyaltysettingData() {
			let params = {
				code: 'serviceroyaltysetting'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value == 'false') {
						this.serviceroyaltysetting = false;
					} else if (res.data.value == 'true') {
						this.serviceroyaltysetting = true;
					}
					this.goodsObj = res.data;
				}
			})
		},
		//收银短信
		getRechargemessageData() {
			let params = {
				code: 'rechargemessage'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value == 'false') {
						this.rechargemessage = false;
					} else if (res.data.value == 'true') {
						this.rechargemessage = true;
					}
					this.rechargeObj = res.data;
				}
			})
		},
		//收银短信
		getCashiermessageData() {
			let params = {
				code: 'cashiermessage'
			}
			this.$http.get("/cms/System/GetSystemConfigByCode", params).then(res => {
				if (res.code === 0) {
					if (res.data.value == 'false') {
						this.cashiermessage = false;
					} else if (res.data.value == 'true') {
						this.cashiermessage = true;
					}
					this.cashierObj = res.data;
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	background: #fff;
	width: calc(100% - 64px);
	height: calc(100% - 64px);
	border-radius: 20px;
	padding: 32px;
	overflow-y: scroll;
	font-size: 14px;

	.viewTitle {
		width: 160px;
		padding-left: 9px;
		font-size: 16px;
		font-weight: bold;
		background: url(../../../assets/img/cangku/detailName.png) no-repeat;
		background-position: left top;
		background-size: 16px 16px;
		margin-bottom: 20px;
	}

	.comView {
		margin-bottom: 20px;
	}

	.comName {
		margin-bottom: 12px;
	}

	.comImg {
		width: 40px;
		height: 40px;
		background-color: #fff;

		// border: 1px solid gray;
		img {
			width: 100%;
			height: 100%;
		}
	}

}

.page::-webkit-scrollbar {
	display: none;
}
</style>
